<template>
  <v-item-group>
    <v-row>
      <template
        v-for="item in getAccesoDirecto(currentUser.accesos, 'type', 7)"
      >
        <v-col :key="item.key" cols="12" sm="3">
          <v-item v-slot:default="{ toggle }">
            <v-card height="100" @click="toggle" flat :to="item.path">
              <v-list-item three-line>
                <v-list-item-content class="align-self-start">
                  <v-list-item-title class="subtitle-1 font-weight-bold mb-2">{{
                    item.title
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar tile size="60">
                  <v-icon size="50">{{ item.icon }}</v-icon>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-item>
        </v-col>
      </template>
    </v-row>
  </v-item-group>
</template>
<script>
export default {
  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
    canal() {
      return this.$store.getters.canal;
    },
  },
  methods: {
    getAccesoDirecto(list, property, value) {
      return list === undefined
        ? []
        : list.filter((c) => c[property] === value && c.canal === this.canal);
    },
  },
};
</script>
<style scoped>
a.v-card.v-card--flat.v-card--link.v-sheet.theme--light {
  box-shadow: 10px 10px 10px rgb(0 0 0 / 5%) !important;
}
/* a.v-card.v-card--flat.v-card--link.v-sheet.theme--light {
  border-radius: 20px;
} */
</style>